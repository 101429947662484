export const topVideoLinkID = "330665335";

export const seoKeywords = [
    `takavfx`,
    `takahide`,
    `nakamura`,
    `taka`,
    `vfx`,
    `aau`,
    `academy of art`,
    `digital garden`
]

export const topVideos = [
    {
        name: "Compositing Reel",
        image: require("../images/thumbnails/Thumb_Compositing_Reel.png"),
        vimeoId: "334556964"
    },{
        name: "Dynamics Reel",
        image: require("../images/thumbnails/Thumb_Dynamics_Reel.png"),
        vimeoId: "334561820"
    },{
        name: "Match Moving Reel",
        image: require("../images/thumbnails/Thumb_MatchMoving_Reel.png"),
        vimeoId: "334554738"
    },{
        name: "Texturing & Lighting Reel",
        image: require("../images/thumbnails/Thumb_Texture_n_Lighting_Reel.png"),
        vimeoId: "334560006"
    }
]
