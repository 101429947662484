import React, { Component } from 'react'
import styles from "./style.module.css";

export default class Grid extends Component {
  
    constructor(props){
        super(props);
        this.state = {
            modalOpen: false
        }
    }

    onClick=(item)=>{
        this.props.onOpenModal(item);
    }
  
    render() {
        return (
            <div className={styles.grid}>
            {
                
                this.props.data.map((item, index)=>{
                    if(!this.props.withTitle){
                        return (
                            <div className={styles.video} key={`video_${index}`}>
                                <div    className={styles.thumbnail}
                                        key={`video_${index}`}
                                        style={{ "backgroundImage":`url(${item.image})` }}
                                        onClick={()=>this.onClick(item)}>

                                        <div className={styles.info}>
                                            <div>
                                                <h1>{item.name}</h1>
                                            </div>
                                        </div>

                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div className={styles.video} key={`video_${index}`}>
                                <div className={styles.thumbnail}    key={`video_${index}`}
                                        style={{ "backgroundImage":`url(${item.image})` }}
                                        onClick={()=>this.onClick(item)}>
                                </div>
                                <div className={styles.descriptions}>
                                    <h4>{item.name}</h4>/<label>{item.year}</label>
                                </div>
                            </div>
                        )
                    }
                })
                
            }
            </div>
        )
  }
}
