import React, { Component } from 'react'
import styles from "./style.module.css"

export default class Modal extends Component {

    onCloseModal = () => {
        this.props.onCloseModal();
    }

    getIframeScale = () =>{
        if(typeof window !== 'undefined' && window){
            return {
                w: window.innerWidth * 0.8,
                h: window.innerWidth *0.8 / 1.7777,
            }
        }else{
            return {w:0,h:0}
        }
    }

    render() {
      console.log(this.props.data)
        var iframeScale = this.getIframeScale();
        return (
            <div className={styles.modalWindow} onClick={this.onCloseModal}>
                <iframe src={`https://player.vimeo.com/video/${this.props.data.vimeoId}?color=ffffff&title=0&byline=0&portrait=0`} frameBorder="0" width={iframeScale.w} height={iframeScale.h}></iframe>
            </div>
        )
    }
}
