import React from "react";
import styles from "./styles/title.module.css"

const Title = (props) => {
    return(
        <div className={styles.root}>
            <h1 className={styles.title}>{props.title}</h1>
            {props.children}
        </div>
    )
}

export default Title;