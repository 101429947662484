import React, { Component } from 'react'
import styles from "./style.module.css"

export default class Footer extends Component {
    render() {
        return (
            <div className={styles.footer}>
                <label>© 2019 Takahide Nakamura All Right Reserved</label>
                <ul>
                <li><a href="https://www.facebook.com/takahide.nakamura.52" className={styles.facebook}></a></li>
                <li><a href="https://www.instagram.com/tnakvfx/" className={styles.instagram}></a></li>
                <li><a href="https://vimeo.com/takahide" className={styles.vimeo}></a></li>
                <li><a href="#" className={styles.twitter}></a></li>
                <li><a href="https://www.linkedin.com/in/takahide-nakamura-8a7778a2/" className={styles.linkedin}></a></li>
                </ul>
            </div>
        )
    }
}
