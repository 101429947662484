import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React, {Component} from "react"
import styles from "./styles/header.module.css";

const headerStates = {
  default: -1,
  fixed: 0
}

class Header extends Component{

  constructor(props){
    super(props);

    this.headerState = headerStates.default

  }
/*
  onClick = (e) =>{
    var attr = e.target.getAttribute("href");
    
    console.log(this);
    if(attr === "/"){
      this.headerState = headerStates.fixed
    }else {
      this.headerState = headerStates.default
    }

  }
*/
  render(){

    return(
      <header className={styles.header}>
          <div>
            <Link to="/">
              <div className={styles.logoWrapper}>
                <h1 className={styles.logo}>Takahide Nakamura</h1>
                <h2 className={styles.title}>Compositor/VFX Artist</h2>
              </div>
            </Link>
            
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/work">Work</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>

          </div>
      </header>
    )
  }


}

/*
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
*/
export default Header
