import React,{Component}from "react"
import { Link } from "gatsby"
import Title from "../components/title"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styles from "./styles/home.module.css"
import { topVideoLinkID ,seoKeywords, topVideos } from "../data/home"
import Grid from "../components/gridView";
import Modal from "../components/modal"

class IndexPage extends Component{

  constructor(props){
    super(props);
    this.state = {
      modalOpen: false,
      currentModalData: null
    }
  }

  onOpenModal = (data) =>{
    this.setState({
      modalOpen: true,
      currentModalData: data
    });
  }

  onCloseModal = () =>{
    this.setState({
      modalOpen: false,
      currentModalData: null
    });
  }

  render(){

    return(
      <Layout className={styles.forceFullscreenLayout}>

        <SEO title="Home" keywords={seoKeywords} />
        {this.state.modalOpen ? <Modal onCloseModal={this.onCloseModal} data={this.state.currentModalData} /> : ""}

        <div className={styles.mainVideoWrapper}>
          <div className={styles.mainVideo}>
            <iframe src={`https://player.vimeo.com/video/${topVideoLinkID}`} frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>

        <div className={styles.grid}>
          <Grid withTitle={false} onOpenModal={this.onOpenModal} data={topVideos} />
        </div>
      </Layout>
    )
  }
}

export default IndexPage
